import * as React from "react"
import PropTypes from "prop-types"
import { getFormatedDay, getDay, isConfPassed, hasRemainingConfs, isIncoming } from "../../helpers/helpers";
import Label from "../shared/label";
import Conf, {ConfItem, ConfRow} from "../shared/conf";
import { TimeIco } from "../shared/icos";


const ColumnItem = (props) => {
    const { conf, isPassed, isNext } = props;

    return (
        <li className={`py-6 ${isPassed ? 'opacity-35' : ''}`}>
            <Conf classes={`${isNext ? 'bgradient p-6 rounded-6.25' : ''}`}>
                <h3 className={`${isNext ? 'text-white text-2xl font-medium leading-7.5' : 'text-2xl leading-7.5'}`}>{ conf.title }</h3>

                <ConfRow classes={`mt-4 p-4 ${isNext ? 'rounded-3.75 conf-bg space-x-8 text-white text-5' : 'border-0.25 rounded-3.75 text-borange border-bxlight-gray'}`}>
                    <ConfItem classes={`flex-none`} picto={<TimeIco color={'#91C36E'} classes={`flex-none mr-2 w-6 h-6`}/>} text={`${conf.startHour}:${conf.startMinutes} - ${conf.endHour}:${conf.endMinutes}`}/>
                </ConfRow>
            </Conf>
        </li>
    )
}

const Column = (props) => {
    const { allConfs, day, currentDate } = props;
    const confs = day.confs;
    const title = <Label variation={'gray'}>{ getFormatedDay(getDay(day)) }</Label>
    const hasRemaining = hasRemainingConfs(day, currentDate);

    return (
        <li className={`w-1/3`}>
            <div className={`mb-11`}>
                { title }
            </div>

            <ul className={`px-6 rounded-6.25 border-0.25 border-bxlight-gray divide-y divide-bxlight-gray ${!hasRemaining ? 'opacity-35' : ''}`}>
                { confs && confs.map((conf, index) => {
                    const isNext = isIncoming(allConfs, currentDate, day, conf);

                    return  (
                        <ColumnItem key={index} conf={conf} isPassed={isConfPassed(day, conf, currentDate)} isNext={isNext}/>
                    )
                })}
            </ul>
        </li>
    )
}

Column.propTypes = {
    allConfs: PropTypes.array.isRequired,
    day: PropTypes.object.isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
}

export default Column
